.ant-tag-cyan {
  background: rgb(0, 255, 255, 0.34);
  border-color: #00ffff;
  border-radius: 4px;
  color: #fff;
  width: 80px;
  text-align: center;
}
.ant-tag-green {
  background: rgba(47, 177, 76, 0.34);
  border-color: #2fb14c;
  border-radius: 4px;
  color: #fff;
  width: 80px;
  text-align: center;
}

.ant-tag-purple {
  background: rgba(138, 43, 226, 0.34);
  border-color: #8a2be2;
  border-radius: 4px;
  color: #fff;
  width: 80px;
  text-align: center;
}
.ant-tag-yellow {
  background: rgba(255, 252, 127, 0.34);
  border-color: #ffff00;
  border-radius: 4px;
  color: #fff;
  width: 80px;
  text-align: center;
}
.dot-icon {
  font-size: 24px;
}
