@use "../../../MainStyle";
// @import url("https://fonts.cdnfonts.com/css/axiforma");

.Dashboard {
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  height: 89vh;
}

.DashboardContent {
  height: 89vh;
  overflow-y: scroll;
}
.calculation-bottom {
  margin-bottom: 0rem;
}
.height-100 {
  height: 100%;
}
.align-center-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .DashboardContent::-webkit-scrollbar {
//   display: none;
// }
.DashboardContent {
  padding: 3rem;
}

.Dashboard_modal {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem;
  width: 126%;
}

.Dashboard_graph {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.Dashboard_head_box_shadow {
  box-shadow: 0px 39px 116px rgba(28, 56, 146, 0.14) !important;
}

.Dashboard_head_box {
  background-color: MainStyle.$theme-background-third;
  // padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  padding: 0.25rem 1rem 0.25rem 1rem;

  & h2 {
    color: #fff;
    font-size: 20px;
  }

  & p {
    color: #fff;
    // color: rgb(172, 172, 172);
  }

  & img {
    height: 30px;
    width: 30px;
  }
}

.Dashboard_body {
  margin-top: 3rem;
  display: grid;
  grid-gap: 1rem;
}

.Dashboard_graph_box {
  background-color: MainStyle.$theme-background-third;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  backdrop-filter: blur(5.627785682678223px);
}

.Dashboard_head_content {
  height: 100%;
}

.dashboard-datepicker {
  height: 75%;
  background-color: #191d23 !important;
  color: #fff !important;
}

.dashboard-datepicker .ant-picker-panel {
  background-color: #191d23 !important;
  color: #fff !important;
}

.dashboard-datepicker .ant-picker-dropdown .ant-picker-panel-container {
  background-color: #191d23 !important;
  color: #fff !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
  background-color: #191d23 !important;
  color: #fff !important;
}

.dashboard-datepicker .ant-picker-dropdown .ant-picker-cell-in-view {
  border: none !important;
}

.dashboard-datepicker
  .ant-picker-dropdown
  .ant-picker-month-panel
  .ant-picker-cell-inner {
  color: #fff !important;
}

.ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-inner {
  color: #fff !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  color: #fff !important;
  background-color: MainStyle.$theme-btn !important;
}

.ant-picker-dropdown .ant-picker-header-view button {
  color: #fff !important;
}

.dashboard-datepicker.ant-picker .ant-picker-input > input {
  color: #fff !important;
}

.ant-picker .ant-picker-suffix {
  color: #fff !important;
}

// .dashboard-datepicker.ant-picker tbody,
// td,
// tfoot,
// th,
// thead,
// tr {
//   border: none !important;
// }
.dash-card {
  padding: 10px;
}

.ant-picker-dropdown .ant-picker-header > button {
  color: #fff;
}

//aivolvex-dashboard-body
.Dashboard_count_graph {
  background-color: #292b57;
  /* display: flex; */
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  border-radius: 10px;
  padding: 0.25rem 1rem 0.25rem 1rem;
  //   margin-top: 1rem;
}

.apexcharts-menu-icon {
  display: none;
}

.chart-dropdown-icon-text {
  font-size: 21px;
}

.chart-dropdown {
  margin-left: 95%;
  font-size: 21px;
  //  float:right !important;
}

.aivolvex-dashboard-body {
  padding: 0rem 2rem 3rem;
  background: MainStyle.$theme-background-secondary;
  min-height: 100vh;
}

.aivolvex-dashboard-outline {
  background: linear-gradient(
    to top,
    transparent,
    rgb(255, 255, 255),
    rgb(255, 255, 255)
  );
  border-radius: 10px;
  // padding: 0.6px;
  padding: 0.8px;
  display: inline-block;
}

// .chart-dropdown-btn{
//   background-color: #fff;
//   color:#0000;
//   border: none;
// }

.dashboard-credit {
  color: MainStyle.$theme-btn;
  font-weight: 600;
}

.modalcard1 {
  width: 50%;
}

.model-table-header {
  font-size: 1.5rem;
}

.dashboard-sub-header {
  font-weight: 400;
  font-size: 21px;
}

.table-usage {
  margin: 1rem;
}
.recent-search {
  border: 1px solid MainStyle.$theme-btn !important;
  background: transparent;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 0.7rem 0rem;
}

.recent-search .recent-search-dropdown .ant-select-selector {
  background: transparent !important;
  padding: 0px 5px 0px 10px;
  border: none;
  border-radius: 0px;
  border-right: 1px solid #b9bac4 !important;
  height: 100%;
  color: MainStyle.$theme-font-primary;
  font-family: "DM Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  width: 9rem;
}

.recent-search
  .recent-search-dropdown
  .ant-select-selector
  .ant-select-selection-item {
  color: MainStyle.$theme-title-font;
  font-family: "DM Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.recent-search .recent-search-dropdown .ant-select-arrow {
  color: MainStyle.$theme-font-primary;
}

.recent-search-input {
  background: transparent;
  border: none;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.31);
  font-family: "DM Sans";
  font-size: 17px;
  font-weight: 500;
}

.model-selector .anticon,
.ant-select-dropdown .anticon-check {
  color: MainStyle.$theme-btn;
}

.dashboard-token-input {
  width: 100%;
  background-color: transparent;
  font-weight: 300;
}
.calculator-header {
  margin-bottom: 0.5rem;
  font-size: 20px;
  font-weight: 500;
}
.dashboard-token-input.ant-input-number-focused,
.dashboard-token-input.ant-input-number:focus,
.dashboard-token-input.ant-input-number:hover {
  border-color: MainStyle.$theme-btn;
}
.ant-input-number
  .ant-input-number-handler:hover
  .ant-input-number-handler-up-inner,
.ant-input-number
  .ant-input-number-handler:hover
  .ant-input-number-handler-down-inner {
  color: MainStyle.$theme-btn;
}
.dashboard-token-input .ant-input-number-input {
  color: MainStyle.$theme-font-primary;
}
.dashboard-token-input .ant-input-number-handler-wrap {
  background: transparent;
}
.dashboard-token-input.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler {
  border: none;
}

.register-button {
  background: MainStyle.$theme-btn !important;
  padding: 0rem 2rem;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid MainStyle.$theme-btn !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-table-body {
  background-color: var(--theme-background-third);
  padding: 2rem;
  margin-top: 6rem;
  border-radius: 15px;
}

.aivolvex-dashboard-modeltoken-scroll {
  height: 11rem;
  overflow-y: auto;
}

.aivolvex-dashboard-modeltoken-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.aivolvex-dashboard-modeltoken-scroll::-webkit-scrollbar-track {
  background-color: #fae8f6;
  border-radius: 0.5rem;
}

.aivolvex-dashboard-modeltoken-scroll::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid #fae8f6;
}

.table-usage.ant-table-wrapper .ant-table-thead > tr > td {
  color: #ff6a2f;
  text-align: center;
  border: none;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder :hover,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent !important;
}
.ant-empty-normal .ant-empty-description:hover,
.ant-empty-normal .ant-empty-description {
  color: #fff;
}

.table-usage.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #202020 !important;
  border-top: 0.474px solid #84848484;
  border-bottom: transparent !important;
}

.table-usage .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.info-icon {
  color: grey;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  height: 8%;
  width: 8%;
}

.aivolvex-dashboard-model-token {
  color: #fff;
}

.form-submit-btn {
  color: #fff;
  display: flex;
  justify-content: end;
}

.delete-icon {
  color: #fff;
  font-size: 20px;
}

.ant-modal .ant-modal-close {
  color: #fff !important;
}

.ant-btn-primary:hover {
  background-color: var(--theme-btn) !important;
}

.description-popup {
  text-align: center;
  white-space: nowrap;
}

.ant-popover .ant-popover-inner {
  background-color: var(--theme-background-primary);
  color: #fff;
}

.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-description {
  color: #fff;
}

.description-popup-icon {
  font-size: 24px;
  color: #fff;
}

.ant-popconfirm-message-icon {
  display: none;
}

.edit-icon {
  color: var(--theme-btn);
  font-size: 20px;
}

.curl-main-card {
  background-color: #111111;
  border: none;
  border-radius: 4px;
  min-height: 30vh;
}

.curl-main-card-header {
  display: flex;
  justify-content: end;
  background-color: #222222;
  border-radius: 4px;
  padding: 5px;
}

.success-color {
  color: #00a300;
  font-size: 20px;
}
.note-text {
  font-size: 13px;
  line-height: 15px;
  color: #7d7d7d;
}
.model-selector.ant-select .ant-select-selector {
  border: 0.5px solid #48495e !important;
}

.model-selector.ant-select .ant-select-selector:hover {
  border: 0.5px solid #48495e !important;
}

.model-selector.ant-select .ant-select-arrow {
  color: #48495e;
}

.recent-prompts-table::-webkit-scrollbar-thumb {
  background: var(--theme-background-primary) !important;
  border-radius: 10px;
}

.recent-prompts-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: var(--theme-background-primary) !important;
  border-radius: 10px;
  border: none;
}

.ant-popconfirm-buttons .ant-btn-primary {
  background-color: var(--theme-btn) !important;
  color: #fff;
}

@media (max-width: 320px) {
  .volvex-chart-popup {
    width: 100%;
  }

  .volvex-chart-popup .ant-popover .ant-popover-content {
    position: relative;
    width: 13rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }

  // .ant-popover .ant-popover-content {
  //   position: relative;
  //   width: 13rem;
  //   margin-left: 0.5rem;
  //   margin-right: 1rem;
  // }
  .volvex-chart-popup .ant-popover .ant-popover-inner {
    width: 14rem;
  }

  // .ant-popover .ant-popover-inner {
  //   width: 14rem;
  // }
  .aivolvex-dashboard-popconfirm-content {
    margin-left: 2rem !important;
  }

  .table-usage {
    margin: 0.1rem;
  }
}
