@use "../../MainStyle";
@use "../../styles/mainsource";

.signin-header {
  font-size: 41px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
}

.sign-in-subheader {
  color: mainsource.$color-header-grey;
}

.sign-in-form {
  width: 80%;
}
.signincontainer {
  background: #080712;
}
.signin-button-linkedin {
  background: mainsource.$linkedin-color;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  color: white;
}

.signin_logo {
  width: 87%;
}

.signin-button-linkedin:hover {
  cursor: pointer;
}

.separator-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid mainsource.$separator-line;
  line-height: 0.1em;
  margin: 5px 0 10px;
  margin-top: 1rem;
}

.separator-line span {
  background: MainStyle.$theme-background-primary;
  padding: 0 10px;
  font-size: 14px;
  color: #fff !important;
}

.forgot-password {
  color: #1c3892;
  font-weight: 550;
  cursor: pointer;
}

.mobile_signin_logo {
  width: 100%;
}

.signup-secondary {
  // color: mainsource.$color-blue;
  background: mainsource.$color-white;
  border: 1px solid mainsource.$color-blue;
}
.login-main {
  position: relative;
}
.login-modal-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.login-tagline-logo {
  top: 0%;
  left: 0%;
  position: absolute;
}
.login-modal-container {
  min-height: 100vh;
  display: flex;
  top: 0%;
  left: 40%;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-message {
  width: 100%;
  display: flex;
  margin: 1rem;
  justify-content: center;
  color: MainStyle.$theme-font-primary;
}
.login-message > strong {
  background: linear-gradient(
    to left,
    #22dcff 0%,
    #5f3bb9 34.73%,
    #e11958 80.74%,
    #e99b12 101.03%
  );
  cursor: pointer;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.login-modal {
  background: rgba(8, 7, 18, 0.87);
  border: 0.7px solid #89898989;
  border-radius: 15px;
  width: 135%;
  color: MainStyle.$theme-font-primary;
}
.login-banner {
  max-height: 100vh !important;
  min-width: 100%;
  // background: url("../../assets/sign-in-background.png") no-repeat center center;
  // background-color: MainStyle.$theme-background-primary;
  // background-image: linear-gradient(150deg, #2c0e34 5%, MainStyle.$theme-background-primary 44%) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.login-logo {
  margin: 1rem 0;
  border-top: 2px solid;
  padding: 1rem 0;
  border-image: linear-gradient(
      to right,
      #22dcff 0%,
      #5f3bb9 34.73%,
      #e11958 80.74%,
      #e99b12 101.03%
    )
    1;
}
.login-font {
  color: MainStyle.$theme-font-primary;
  font-size: 3rem;
  font-style: normal;
  font-weight: 350;
  line-height: 2.6rem;
  letter-spacing: 0.5rem;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 5rem;
}
.login-content {
  display: flex;
  justify-content: end;
}
.login-modal-header > strong {
  font-weight: 600;
}
.login-modal-header {
  color: MainStyle.$theme-font-primary;
  border-bottom: 2px solid;
  border-image: linear-gradient(
      to right,
      #22dcff 0%,
      #5f3bb9 34.73%,
      #e11958 80.74%,
      #e99b12 101.03%
    )
    1;
  font-weight: 300;
  width: fit-content;
  padding: 5px;
  margin-top: 2rem;
}

.login-form .ant-form-item {
  margin: 2% 7%;
  width: 86%;
}
.login-form .ant-input {
  border-color: #cbd1d8;
  border: 0.5px solid #cbd1d8 !important;
}
.login-form .ant-input:focus,
.login-form .ant-input:hover {
  border-color: MainStyle.$theme-btn;
  border: 0.5px solid MainStyle.$theme-btn !important;
}
.login-form .login-form-button {
  margin-bottom: 2rem;
  font-weight: 300;
}
.login-form
  .ant-form-item
  .ant-form-item-control-input
  .ant-btn.ant-btn-primary {
  background-color: MainStyle.$theme-btn;
  width: 100%;
  margin-top: 2%;
}
.login-form {
  width: 100%;
}
.login-form-forgot {
  color: MainStyle.$theme-font-primary;
}
.login-form-button > span {
  font-weight: 600;
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: linear-gradient(90deg, #f33a56 -1.19%, #6e039f 164.88%);
  /* Change the active dot color */
}

.carousel-indicators [data-bs-target].active {
  width: 30px !important;
  height: 9px !important;
  background: linear-gradient(90deg, #f33a56 -1.19%, #6e039f 164.88%);
  border-top: 0px;
  border-bottom: 0px;
  border-radius: 3rem;
}

.plat-login-azure-btn {
  background: #16182e;
  border: 1px solid #000;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 3rem;
  padding: 10px 0px;
  /* margin-bottom: 1rem; */
  width: 100%;
}

//popup

// .login-azure-ad-model > .ant-modal-content {
//   background-color: #16182e;
//   color: #fff;
// }
// .lable-white > .ant-form-item-row > .ant-form-item-label > lable {
//   color: #fff !important;
// }

.plat-login-button {
  background: #db2aac;
  color: #fff;
  border: none !important;
}

.plat-login-button:hover {
  background: #db2aac;
  color: #fff !important;
}

.signup-secondary:hover {
  color: mainsource.$color-white;
  background: mainsource.$color-blue;
  border: 1px solid mainsource.$color-blue;
}

.signup-option {
  color: mainsource.$color-grey;
  font-weight: mainsource.$font-weight400;
  opacity: 60%;
}

.remember-me {
  color: mainsource.$color-header-grey;
  opacity: 65%;
}

.mobile-margin {
  margin-top: 2px;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  // bottom: 5rem;
  top: 95%;
  bottom: 2rem;
}

p.siginbanner-heading {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal;
  color: #fff;
}

.siginbanner-1 {
  position: absolute;
  top: 101px;
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  // padding-left: 78px;
  padding-left: 19px;
}

.carousel-header {
  color: mainsource.$color-carousel-header;
  font-weight: mainsource.$font-weight500;
  font-size: larger;
}

// .carousel-text {
//   margin-top: 15rem;
// }

.carousel-content {
  color: mainsource.$color-header-grey;
  opacity: 60%;
  margin-top: 1rem;
}

.signup-options {
  background: mainsource.$color-white;
  border: 1px solid #00000014;
  border-radius: 7px;
  height: 4rem;
  font-size: 0.9rem;
  cursor: pointer;
  width: 100% !important;
}

.gigs-active,
.talent-active {
  background: mainsource.$color-white;
  border: 1px solid #f33a56;
  border-radius: 7px;
  height: 4rem;
  font-size: 0.9rem;
  cursor: pointer;
  box-shadow: 0px 29px 60px rgba(28, 56, 146, 0.12);
  border-width: 1.5px;
  border: 1px solid transparent;
  border-radius: 7px;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #f33a56, #6e039f);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.options-logo {
  height: 2rem;
  margin-left: 1rem;
}

.options-title {
  margin-top: 0.3rem;
  margin-left: 2rem;
  color: #23232399;
}

.width-100 {
  width: 100% !important;
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25) !important;
}

.signin > .ant-input {
  color: #fff !important;
  background: #16182e !important;
  border: 0.5px solid #48495e;
}

.form-control.signin {
  display: block;
  font-size: 0.8rem !important;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 2;
  color: #fff;
  background-color: #16182e !important;
  background-clip: padding-box;
  border: none !important;
  appearance: none;
  border-radius: 9px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control.signin::placeholder {
  color: rgba(255, 255, 255, 0.23);
  /* Change this to the desired color */
}

.signIn-font-remeber {
  color: #cbcbcb;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-control.signup {
  display: block;
  font-size: 0.8rem !important;
  width: 100%;
  padding: 0.375rem 0.65rem;
  font-weight: 400;
  line-height: 1.5;
  color: #141619;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.success-message {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-icon {
  font-size: 2.5em;
  color: mainsource.$color-blue;
}

.warning-icon {
  font-size: 2.5em;
  color: rgb(237 205 75);
}

.width-60 {
  width: 61%;
}

.success-fontsize {
  font-size: 1.2rem;
}

.bg-color {
  background: #f5f5f5;
}

.navlogo.signinLogoBanner {
  left: 6%;
  position: absolute;
  z-index: 1111111;
  top: 4%;
}

.resetPassword-mainContainer {
  margin-top: 1.75rem;
  margin-bottom: 2.5rem;
}

.resetPasswordField {
  font-size: 0.75rem;
  padding: 0.5rem 0rem !important;
}

.resetPasswordButtonDiv {
  justify-content: center !important;
}

.resetPasswordButton {
  background: #1c3892;
  padding: 0.4rem 2rem;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #1c3892 !important;
}

.resetPasswordConfirmation {
  font-size: 0.8rem;
  font-weight: 600;
  color: rgba(35, 35, 35, 0.5);
}

.agreeTermsAndConditionsButton {
  background: linear-gradient(93.22deg, #f33a56 3.99%, #6e039f 300.47%);
  border: none;
  padding: 0.6rem 2rem;
  font-size: 0.8rem;
  border-radius: 3px;
}

.agreeTermsAndConditionsButton:hover {
  background: linear-gradient(93.22deg, #f33a56 3.99%, #6e039f 300.47%);

  border: none;
}

.linkedInButtonDiv-mobile {
  width: 100% !important;
}

.googlebuttonDiv-mobile {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

// .linkedInButtonMobile {
//   display: none !important;
// }

.linkedInButtonDesktop {
  display: block !important;
}

.forgotPasswordDesktopView {
  display: block !important;
}

// .forgotPasswordMobileView {
//   display: none !important;
// }

#termsAndConditionPopupScrollBar::-webkit-scrollbar {
  width: 1em;
}

#termsAndConditionPopupScrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#termsAndConditionPopupScrollBar::-webkit-scrollbar-thumb {
  background-color: red;
  outline: 1px solid red;
}

.carousel-content p {
  font-size: 12px;
}

.button-secondary.signin {
  padding: 0.5rem 2rem;
}

.button-secondary.signin:hover {
  padding: 0.5rem 2rem;
}

span.font-align-center {
  font-size: 12px;
}

.signup-container {
  padding: 0.5rem 4rem;
}

.modal-dialog-scrollable .modal-body {
  line-height: 25px;
}

// @media only screen and (min-width: 427px) {
//   .navLogoSignIn {
//     display: none;
//   }
// }
.remember-me {
  display: flex;
  width: 75% !important;
}

.googlediv {
  display: flex;
  justify-content: center;
}

.navlogo-sigin {
  margin-top: 15px;
  margin-left: 7px;
  width: 45px !important;
  height: 30px !important;
}

// @media only screen and (max-width: 768px) {
//   .socials-div-mobile {
//     display: block !important;
//   }
//   .navLogoSignIn {
//     width: 8rem;
//     height: 3rem;
//     justify-content: center;
//   }
//   .signup-container {
//     padding: 0.5rem 2rem;
//   }

//   .agreeTermsAndConditionsButton {
//     width: 50% !important;
//     padding: 0.6rem 2rem;
//     font-size: 0.8rem;
//   }

//   label {
//     display: block;
//     text-align: initial;
//   }

//   .forgot-password {
//     font-size: 0.65rem;
//   }

//   // .forgotPasswordDesktopView {
//   //   display: none !important;
//   // }

//   .forgotPasswordMobileView {
//     display: block !important;
//   }

//   .options-logo {
//     height: 1.5rem;
//     margin-left: 0rem !important;
//   }

//   .options-title {
//     margin-top: 0.3rem;
//     margin-left: 0rem !important;
//     color: #23232399;
//     font-size: 0.75rem;
//   }

//   .separator-line span {
//     font-size: 0.75rem !important;
//   }

//   // .signin-button-linkedin {
//   //   font-size: 0.75rem;
//   // }

//   .linkedInButtonMobile {
//     display: block !important;
//     font-size: 0.75rem !important;
//     width: 100% !important;
//     margin-bottom: 1rem !important;
//   }

//   // .linkedInButtonDesktop {
//   //   display: none !important;
//   // }
// }
.firebasegoogle {
  background: #db4437;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  color: white;
}

.firebasegoogle:hover {
  background: #f33a56;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  color: white;
}

.googlebtnicon {
  margin-right: 0.5rem;
}

ol {
  counter-reset: item;
  padding-left: 0;
}

li {
  display: block;
}

.terms > li:before {
  content: counters(item, ".") ".";
  counter-increment: item;
}

.desktop-logo {
  display: block;
}

.mobile-logo {
  display: none;
}

.login-padding {
  padding: 3rem !important;
}

.d-flex-remember-w {
  width: 100% !important;
}

.signup-here-web-prefix {
  color: #fff;
}

.signup-web-here {
  color: #de2bae;
  padding-left: 5px;
}

.web-signup-here-btn {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.main-container {
  background-color: #080712;
}

// @media only screen and (max-width: 768px) {
//   .mobileView {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     height: 100vh;
//   }
//   .mobileHeader {
//     display: flex;
//     flex-direction: column;
//   }
//   .signin-header {
//     text-align: left;
//   }
//   .sign-in-subheader {
//     text-align: left;
//   }
// }

// @media only screen and (max-width: 2304px) and (min-width: 2161px) {
//   .siginbanner-1 {
//     padding-left: 18rem;
//   }
// }
// @media only screen and (max-width: 2160px) and (min-width: 1922px) {
// }
// @media only screen and (max-width: 1921px) and (min-width: 1681px) {
// }
// @media only screen and (max-width: 1680px) and (min-width: 1601px) {
// }
// @media only screen and (max-width: 1600px) and (min-width: 1471px) {
// }
// @media only screen and (max-width: 1470px) and (min-width: 1441px) {
// }
// @media only screen and (max-width: 1440px) and (min-width: 1367px) {
// }
// @media only screen and (max-width: 1366px) and (min-width: 1281px) {
// }
// @media only screen and (max-width: 1280px) and (min-width: 1081px) {
// }
// @media only screen and (max-width: 1080px) and (min-width: 1025px) {
// }
// @media only screen and (max-width: 1024px) and (min-width: 769px) {
// }

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }

  .login-padding {
    padding: 1rem !important;
  }

  .d-flex-remember-w {
    width: 76% !important;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }

  .login-padding {
    padding: 1rem !important;
  }

  .d-flex-remember-w {
    width: 76% !important;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }

  .login-padding {
    padding: 1rem !important;
  }

  .d-flex-remember-w {
    width: 76% !important;
  }
}

@media (max-width: 320px) {
  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }

  .login-padding {
    padding: 1rem !important;
  }

  .d-flex-remember-w {
    width: 76% !important;
  }
}

.mobile-banner {
  display: none !important;
}

@media (max-width: 576px) {
  .main-container {
    display: none !important;
  }

  .banner-bg {
    // background-image: url("../../assets/mobilebackbanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 700px !important;
  }

  .shadow-img {
    width: 320px !important;
  }

  .mobile-banner {
    display: block !important;
  }

  .mobile-banner-logo {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .mobile-banner-logo-text {
    display: flex;
    justify-content: center;
    /* padding: 10px; */
  }

  .mobile-banner-text {
    background: var(
      --C,
      linear-gradient(
        93deg,
        #eb77e7 27.5%,
        MainStyle.$theme-btn 46.64%,
        #9340e8 68.78%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
  }

  .mobile-banner-text-normal {
    color: #fff;
    text-align: center;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    // line-height: 124%;
  }

  .signin-btn {
    border-radius: 4px;
    background: #de2bae;
    border: none;
    width: 271px;
    color: #fff;
  }

  .signup-btn {
    border-radius: 4px;
    background: transparent;
    border: 1px solid #de2bae;
    color: #de2bae;
    width: 271px;
  }
}

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
  .main-container {
    // background-color: #cbcbcb !important;
    // width: 100% !important;
    height: 100% !important;
  }

  .last-screen {
    // width: 100% !important;
    // height: 1000px !important;
  }
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
}

@media (max-width: 320px) {
}
