@use "MainStyle";

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=Poppins:ital,wght@1,200&family=Roboto:wght@300&display=swap");
/* @import url("https://fonts.cdnfonts.com/css/nordic"); */

/* @font-face {
  font-family: "Nordic";
  src: url("./assets/font/Nordic/Nordic-Sans.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Nordic";
  src: url("./assets/font/Nordic/Nordic-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
} */

body {
  background-color: MainStyle.$theme-background-primary !important;
  /* background-image: linear-gradient(150deg, #2c0e34 5%, MainStyle.$theme-background-primary 44%) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
  font-family: "DM Sans" !important;
}

.conversation-mobile {
  display: none !important;
}

.chat-avator-img {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  border-radius: 50%;
  border: 2px solid #000;
}

.app-secondary-color {
  color: MainStyle.$theme-btn !important;
}

.ant-modal-content {
  background: MainStyle.$theme-background-third !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
}

/* .anticon svg {
  color: #fff !important;
} */
.aivolvex-font {
  font-family: "DM Sans" !important;
}

.text-center {
  text-align: center;
}

.aivolvex-page-title {
  font-size: 20px;
  line-height: 45.92px;
}

.right-padding-2 {
  padding-right: 2rem;
}

.btn-primary-new {
  font-size: 17px;
  background: #db2aac;
  padding: 0.4rem 2rem;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #db2aac !important;
  color: #fff !important;
}

.logo-peta-login {
  color: #db2aac;
  font-size: 15px;
  position: absolute;
  top: 11%;
}

.aivolvex-right {
  text-align: right;
}

.display-none {
  display: none;
}

.white-color {
  color: #fff !important;
}

.site-second-color {
  color: #db2aac !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.body-margin-top {
  margin-top: 6rem;
  /* margin-bottom: 4.5rem; */
}

.right-token-view {
  position: absolute;
  right: 5%;
  top: 37%;
}

/* .body-bg {
  background-color: #fcfcfc !important;
} */
span.ant-avatar.ant-avatar-circle.volvex-chat-avatar-logo.css-dev-only-do-not-override-txh9fw {
  display: flex;
  justify-content: center;
  align-items: center;
}

.width-25 {
  width: 25% !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-bottom-0-5 {
  margin-bottom: 0.5rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}
.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-right-0-5 {
  margin-right: 0.5rem;
}

img.navlogo.cursor-pointer {
  padding: 0 0 1rem 1rem;
  height: 50px;
  width: 150px;
}

.ant-popover-title {
  color: #fff !important;
}

.full-width {
  width: 100% !important;
}

.full-screen-component {
  width: 100vw;
  /* 100% of the viewport width */
  height: 100vh;
  /* 100% of the viewport height */
  background-color: #eee;
  /* Set a background color if needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.recent-table.ant-table > tr > th {
  background-color: #f0f2f5 !important;
  color: red !important;
  font-weight: bold;
}

.window-margin-right {
  margin-right: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.recent-table .ant-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.88);
}

.recent-table .ant-table-thead > tr > th {
  color: #fff !important;
  font-weight: 600;
  text-align: center;
  background: rgba(0, 0, 0, 0.88) !important;
  transition: background 0.2s ease;
  border-bottom: transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.recent-table .ant-table-tbody > tr:nth-child(even) {
  background: linear-gradient(to right, #f0f5ff, #e1f5fe);
}

.recent-table .ant-table-filter-trigger {
  color: white;
}

.history-table .ant-table-filter-trigger {
  color: white;
}

.history-table .ant-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.88);
}

.history-table .ant-table-thead > tr > th {
  color: #fff !important;
  font-weight: 600;
  text-align: center;
  background: rgba(0, 0, 0, 0.88) !important;
  transition: background 0.2s ease;
  border-bottom: transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.history-table .ant-table-tbody > tr:nth-child(even) {
  background: linear-gradient(to right, #f0f5ff, #e1f5fe);
}

.logo-peta {
  color: #db2aac;
  font-size: 12px;
  position: absolute;
  top: 22%;
}

.font-size-20 {
  font-size: 20px;
}

.mar-0 {
  margin: 0rem !important;
}

/* model  */
.ant-modal-title {
  background-color: MainStyle.$theme-background-third;
  color: #fff !important;
}

.ant-modal-body {
  margin: 1rem 0rem;
}

/* end model */
/* drawar style */

.ant-input {
  color: #fff !important;
  background: transparent;
  border: 0.5px solid #48495e;
}

.ant-input:hover {
  border: 0.5px solid #48495e !important;
}

.ant-input::placeholder {
  color: hsla(0, 0%, 100%, 0.29);
}

.ant-drawer-wrapper-body {
  background: MainStyle.$theme-background-secondary;
}

.ant-drawer-title {
  color: #fff !important;
}

label.ant-form-item-required {
  color: #fff !important;
}

.onboard_avatar_updated {
  color: #fff;
}

.ant-form-item-label label {
  color: #fff !important;
}

.ant-upload.ant-upload-select {
  color: #fff;
}

.aivolvex-custom-select {
  background-color: MainStyle.$theme-background-secondary;
}

.ant-select-selector {
  background: transparent !important;
}

.ant-select-selection-search-input {
  color: #fff;
}

.ant-select-selection-item {
  color: #fff;
}

.aivolvex-custom-select .ant-select-selector {
  background: #24264c !important;
  border: 1px solid #4a4747 !important;
}

span.ant-select-selection-placeholder {
  color: #757575 !important;
}

.ant-select-dropdown {
  background-color: MainStyle.$theme-background-third !important;
}

.ant-select-item-option-content {
  color: #fff !important;
}

.ant-select-item-option-active {
  background-color: MainStyle.$theme-background-primary !important;
}

.ant-select-item-option-selected {
  background: MainStyle.$theme-background-primary !important;
}

.aivolvex-ant-upload {
  color: #fff;
  margin-bottom: 2%;
}

.ant-upload-list-item-name {
  color: #fff !important;
}

span.anticon.anticon-ellipsis {
  font-size: 32px;
}

.ant-modal-body {
  color: #fff !important;
}

.aivolvex-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.aivolvex-sub-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* end drawar */
/* start button */
.volvex-btn {
  width: 100%;
  border-radius: 11px !important;
  background: rgba(215, 54, 209, 0.56);
  font-size: 17px !important;
  font-style: normal;
  font-weight: 600;
}

.volvex-btn:hover {
  background: rgba(215, 54, 209, 0.56) !important;
}

/* end button */

/* popup table */
.costimize-bootstrap-table-pop {
  width: 100%;
}

.costimize-bootstrap-table-pop thead th {
  background-color: MainStyle.$theme-background-primary !important;
  /* background-color: #252746 !important; */
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  /* text-align: center !important; */
  z-index: 0 !important;
}

.costimize-bootstrap-table-pop .ant-table {
  background: transparent !important;
  color: #fff;
}

.pricing-info-heading {
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
  text-align: center;
}
.pricing-info-value {
  font-weight: 500;
  margin: 0.7rem;
  color: MainStyle.$theme-btn;
  font-size: 2rem;
  text-align: center;
}
.costimize-bootstrap-table-pop tbody tr td {
  border: none;
  border-bottom: 1px solid #545454 !important;
}

.costimize-bootstrap-table-pop .ant-table-cell-row-hover {
  background: transparent !important;
}
.pricing-table-container {
  // padding: 10px;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-wrapper .ant-table-tbody > tr > th.ant-table-cell-row-hover,
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #080a1e;
}

/*end popup table */

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
}

@media (max-width: 320px) {
}
